import React from "react"
import ChatPage from "../../../components/utils/chatgpt/chatgpt.js"
import Layout from "../../../components/panel/layout";
import Seo from "../../../components/seo";


const ChatgptPage = () => {
    return(
        <Layout>
            <Seo title="سرویس اتصال به ChatGpt" />
            <ChatPage />
        </Layout>
    )
}

export default ChatgptPage
