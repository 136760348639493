import React, { useState } from "react";
import axios from "axios";
import {sg_form_chatgpt, sg_input_chatgpt, sg_bottom_chatgpt} from "../../index.module.css";

const ChatPage = () => {
    const [inputText, setInputText] = useState("");
    const [outputText, setOutputText] = useState([]);


    const accessToken = "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";

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Send a request to ChatGPT API
        const response = await axios.post(
            "https://api.openai.com/v1/completions",
            {
                model: "text-davinci-003",
                prompt: `The following is a conversation with an AI assistant. The assistant is helpful, creative, clever, and very friendly.\n\nHuman: ${inputText}\nAI:`,
                temperature: 0.5,
                max_tokens: 200,
                n: 1,
                stop: "\n"
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        // Update the output text with the response from ChatGPT API
        setOutputText((prevOutputText) => [
            ...prevOutputText,
            {
                user: inputText,
                bot: response.data.choices[0].text,
            },
        ]);

        setInputText("");
    };

    return (
        <div>
            <h1 style={{direction:`rtl`,textAlign:`center`}}>سرویس اتصال به چت بات chatgpt (نسخه آزمایشی)</h1>
            <div style={{direction:`rtl`,textAlign:`right`}}>
                {outputText.map((conversation, index) => (
                    <div key={index}>
                        <p>User: {conversation.user}</p>
                        <p>Bot: {conversation.bot}</p>
                    </div>
                ))}
            </div>
            <form className={sg_form_chatgpt} onSubmit={handleSubmit}>
                <input className={sg_input_chatgpt} placeholder={"برای شروع گفتگو ، اینجا تایپ کنید"} type="text" value={inputText} style={{direction:`rtl`}} onChange={handleInputChange} />
                <button className={sg_bottom_chatgpt} type="submit">Send</button>
            </form>
        </div>
    );
};

export default ChatPage;
